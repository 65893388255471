import {
  createContext,
  useContext,
  ReactElement,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react'
import { CognitoUser } from '@aws-amplify/auth'
import { Auth, Hub } from 'aws-amplify'
import ContextStatusEnum from '../constants/contextStatus.constants'

type ClaimsType = {
  id: string
  name: string
  profilePhoto: string
}

type ContextStatusType = ContextStatusEnum.UNSET | ContextStatusEnum.FINISHED

type UserContextType = {
  user: CognitoUser | null
  setUser: Dispatch<SetStateAction<CognitoUser>>
  status: ContextStatusType
  claims: ClaimsType
  setClaims: Dispatch<SetStateAction<ClaimsType>>
  refreshToken(): Promise<void>
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export default function AuthContext({
  children,
}: {
  children: ReactElement
}): ReactElement {
  const [user, setUser] = useState<CognitoUser | any | null>(null)
  const [status, setStatus] = useState<ContextStatusType>(
    ContextStatusEnum.UNSET
  )
  const [claims, setClaims] = useState<ClaimsType | null>(null)

  useEffect(() => {
    checkUser()
  }, [])

  useEffect(() => {
    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
        case 'signUp':
        case 'signOut':
        case 'tokenRefresh':
          checkUser()
          break
      }
    }
    Hub.listen('auth', listener)
  }, [])

  useEffect(() => {
    if (user) setClaims(JSON.parse(user.signInUserSession.idToken.payload.user))
  }, [user])

  const checkUser = useCallback(async (): Promise<void> => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      setUser(user)
    } catch (e) {
      console.error(e)
      setUser(null)
    } finally {
      setStatus(ContextStatusEnum.FINISHED)
    }
  }, [])

  const refreshToken = useCallback(async (): Promise<void> => {
    try {
      await Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <UserContext.Provider
      value={{ user, setUser, status, claims, setClaims, refreshToken }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = (): UserContextType => useContext(UserContext)
